.nav_bar_wrapper{
    height: 85px;
    border-bottom: 1px solid #6A6666;
}
.nav_bar_container{
    max-width: 1440px;
    margin: auto;
    padding: 0px 83px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.globex_lgo{
    cursor: pointer;
}
.nav_right_wrapper{
    display: flex;
    align-items: center;
    gap: 25px;
}
.nav_link{
    text-decoration: none !important;
    color: white !important;
}
.nav_items{
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    font-family: 'OPTIVenus', sans-serif;
    color: white !important;
    text-decoration: none !important;
}
.nav_items :hover{
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    font-family: 'OPTIVenus', sans-serif;
    color: #3f4089 !important;
    text-decoration: none !important;
}
.nav_bar_mobile_container{
    max-width: 1440px;
    margin: auto;
    padding: 0px 83px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    display: none;
}
.mobile_icon{
    color: white;
    font-size: 25px;
    cursor: pointer;
}
.close_icon{
    color: white;
    font-size: 30px;
}
.side_bar_items{
    display: flex;
    font-size: 16px;
    padding: 5px 10px;
    cursor: pointer;
    color: white !important;
    text-decoration: none !important;
    justify-content: center;
}
.ham_burger{
    display: flex;
    padding: 5px 20px 5px 0px;
    justify-content: space-between;
    align-items: center;
}
.mobile_side_bar{
    background-color: black;
}
@media screen and (max-width:768px){
    .nav_bar_container{
        max-width: 1440px;
        margin: auto;
        padding: 0px 20px 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .nav_bar_mobile_container{
        max-width: 1440px;
        margin: auto;
        padding: 0px 2px 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        display: none;
    }
    .nav_right_wrapper{
        display: flex;
        align-items: center;
        gap: 15px;
    }
}
@media screen and (max-width:500px){
    .nav_bar_container{
        display: none;
    }
    .nav_bar_mobile_container{
        max-width: 1440px;
        margin: auto;
        padding: 0px 20px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        display: flex;
    }
}