.what_we_do{
  max-width: 1440px;
  margin: auto;
  align-items: center;
  padding: 0px 20px;
}
.pro_sec_2{
  margin-top: -30px;
}
.what_we_heading{
  display: flex;
  justify-content: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  font-family: 'OPTIVenus', sans-serif;
  cursor: pointer;
  color: white;
}
.what_sub_heading{
  font-size: 16px;
  font-family: 'OPTIVenus', sans-serif;
  font-weight: 700;
  text-align: center;
}
.conditionl_opt{
max-width: 1440px;
margin: auto;
display: flex;
gap: 20px;
justify-content: space-between;
align-items: center;

padding: 26px 119px 0px;
}
.con_items{
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-family: 'OPTIVenus', sans-serif;
}
.con_items:hover{
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  color: #3f4089 !important;
}
.con_items_active{
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
padding: 0px 10px;
  width: 244px;
  border: 1px solid black;
  height: 120px;
  
  position: relative;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'OPTIVenus', sans-serif;
  cursor: pointer;
  background: url("../../Assests/bgall.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 45px 45px 0px 0px;
}
.con_sep{
  border-bottom: 4px solid white;
  margin: -10px 104px 0px 104px;


}
.digital_marketing_wrap{
  padding: 0px 124px;
  display: flex;
  margin-top: 19px;
  justify-content: space-between;
  align-items: center;
  gap: 33px;
}
.digital_marketing_wrap1{
  padding: 0px 124px;
  display: flex;
  margin-top: 19px;
  justify-content: space-between;

  gap: 33px;
}
.opt_sec3{
  margin-top: 18px;
}
.opt_text{
  font-size: 12px;
  font-weight: 700;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
max-width: 780px;
}
.con_list_con{
  position: absolute;
margin: auto;
width: 180px;
filter: blur(0px);
-webkit-filter: blur(0px);
-moz-filter: blur(0px);
-o-filter: blur(0px);
-ms-filter: blur(8px);


}
.opt_text11{
  font-size: 12px;
  font-weight: 700;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
max-width: 550px;
}
.plus_icon{
  font-size: 10px;
}
.plus_icon1{
  font-size: 13px;
  margin-top: -20px;
}
.opt_text2{
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 10px;
  padding-top: 20px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
max-width: 780px;
}
.email_img{
  padding-top: 13px;
}
.opt_sec5{
  margin-top: 35px;
}
.opt_text29{
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 10px;
  padding-top: 20px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
width: 700px;
}
.opt_text22{
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 25px;
  padding-top: 20px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
max-width: 780px;
}
.opt_last_text{
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 10px;
padding-top: 5px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
max-width: 780px;
}
.product_design{
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 124px 0px 124px;
}
.product_sec1{
  max-width: 349px;
  margin-top: -30px;
}
.pro_sec1_text{
  font-size: 13px;
  font-weight: 700;
  color: white;
  padding-bottom: 40px;
  font-family: 'OPTIVenus', sans-serif;
  line-height: 19px;
}

@media screen and (max-width:1300px){
  .conditionl_opt{
    max-width: 1440px;
    margin: auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    
    padding: 26px 20px 0px 20px;
    }
    .con_sep{
      border-bottom: 4px solid white;
      margin: 0px 0px;
    }
    .digital_marketing_wrap{
      padding: 19px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 33px;
    }
    .digital_marketing_wrap1{
      padding: 19px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 33px;
    }
    .opt_text{
      font-size: 12px;
      font-weight: 700;
      color: white;
      font-family: 'OPTIVenus', sans-serif;
    max-width: 780px;
    }
    .product_design{
      max-width: 1440px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 20px 0px 20px;
    }
    .opt_text29{
      font-size: 12px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 3px;
      padding-left: 0px;
      padding-top: 20px;
      color: white;
      font-family: 'OPTIVenus', sans-serif;
    width: 100%;
    }
}
@media screen and (max-width:950px){
  .conditionl_opt{
    width: 950px;
    margin: auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;

    padding: 26px 20px 0px 20px;
    }
    .product_sec1{
    
      margin-top: 0px;
    }
    .scrol_wrap{
      overflow-y: scroll;
      overflow-x: scroll;
    }
    .con_items_active{
      font-size: 11px;
      font-weight: 700;
      line-height: 16px;
      background:rgba(63, 64, 137 );
      width: 180px;
      border: 1px solid black;
      height: 50px;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      text-align: center;
      background: url("../../Assests/bgall.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      font-family: 'OPTIVenus', sans-serif;
      cursor: pointer;
      
   
    }
}
@media screen and (max-width:800px){
  .product_design{
    max-width: 1440px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 21px 20px 0px 20px;
  }
}
@media screen and (max-width:768px){
  .digital_marketing_wrap{
    padding: 19px 00px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 33px;
  }
  .product_sec1{
    max-width: 349px;
    margin-top: 0px;
  }
  .pro_sec_2{
    margin-top: 15px;
  }
  .digital_marketing_wrap1{
    padding: 19px 00px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 33px;
  }
  .opt_text{
    font-size: 12px;
    font-weight: 700;
    color: white;
    font-family: 'OPTIVenus', sans-serif;
  max-width: 100%;
  }
  .opt_text2{
    font-size: 12px;
    font-weight: 700;
    display: flex;
    /*align-items: center;*/
    gap: 5px;
    padding-left: 0px;
    padding-top: 10px;
    color: white;
    font-family: 'OPTIVenus', sans-serif;
  max-width: 780px;
  }

  .con_items_active{
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    background:rgba(63, 64, 137 );
    width: 190px;
    border: 1px solid black;
    height: 90px;
    display: flex;
    color: white;
  background: url("../../Assests/bgall.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'OPTIVenus', sans-serif;
    cursor: pointer;
  
    border-radius: 45px 45px 0px 0px;
  }
}
@media screen and (max-width:475px){
  .plus_icon1{
    font-size: 20px;
    margin-top: -25px;
  }
  .opt_sec2_plus{
    /*margin-top: -10px;*/
  }
  .opt_sec2_plus1{
    font-size: 18px;
    margin-top: -18px;
  }
  .opt_last_text{
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 0px;

  padding-top: 5px;
    color: white;
    font-family: 'OPTIVenus', sans-serif;
  max-width: 780px;
  }
  .plus_icon_loc{
    font-size: 15px;
    margin-top: -15px;
  }
  .opt_text22{
    font-size: 11px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 3px;
    padding-left: 0px;
    padding-top: 15px;
    color: white;
    font-family: 'OPTIVenus', sans-serif;
  max-width: 780px;
  }
  .con_sep{
    border-bottom: 4px solid white;
    margin: -5px 0px;
    z-index: 5;
  }
}