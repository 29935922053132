.mainFooter {
  height: 50px; 
  background: black;

  display: flex;
  max-width: 1440px;
  margin: auto;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
}
.footer_items{
  font-size: 12px;
  font-weight: 700;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
  cursor: pointer;
  text-align: center;

}
.footer_sep{
  border-top: 1px solid #6A6666;
}


