.divDivider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding: 72px;
}

/* .overlay {
  background: red
} */

.WhoWeAre {
  /* margin: 20px;
    text-align: center;
    background: #f9f5ff;
    padding: 72px; */
}

.WhoWeAre h4 {
  color: white;
  font-weight: bolder;
  font-size: 30px;
}

.paragraph {
  font-size: 20px;
  margin: 16px 0;
  margin-top: 30px;
  margin-bottom: 50px;
}

/* @media screen and (max-width: 600px){
  .divDivider {
    text-align: center;
  }
} */
