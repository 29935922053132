.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-right: 60px;
  margin-left: 60px;
  margin-top: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.addcategory_textarea_sub {
  width: 100%;
  height: 150px;
  background: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  /* border: none; */
  padding: 10px 10px;
  color: black;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input_field_login {
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: black;
  color: white;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
  width: 350px;
}

.input_field_login::placeholder {
  color: white;
}

.input_field_login_email {
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: black;
  color: white;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
}

.input_field_login_email::placeholder {
  color: white;
}

.input_filed_phone {
  padding: 12px;
  margin: 6px 0 4px;
  border: 1px solid #ccc;
  background: black;
  color: white;
  font-family: sans-serif;
  font-size: 12px;
  line-height: normal;
  box-sizing: border-box;
  border-radius: 2px;
  width: 80px;
}
.input_message {
  height: 100px;
}

/* .input_field_login:focus {
  background: gray;
  transition: background 0.1s ease-in;
  transition-property: background;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
} */
.width100 {
  width: 100%;
}
.div_label_input {
  display: flex;
  gap: 30px;
}
.div_div_label_input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dflex {
  display: flex;
}
.justifycontentstart {
  justify-content: flex-start;
}
.flexdirection {
  flex-direction: column;
}
.div_input_phone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.btn_submit {
  background-color: black;
  padding: 4px 16px;
  border-radius: 10px;
  border: 2px solid white;
}

.btn_submit:hover {
  background-color: white;
  padding: 4px 16px;
  border-radius: 10px;
  color: black;
  border: 2px solid black;
}

.fontSize24 {
  font-size: 24px;
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  margin-top: 30px;
  margin-bottom: 70px;
}
.white_color {
  color: #fff;
}

.input_field_login.error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

@media screen and (max-width: 1000px) {
  .contact {
    display: flex;
    flex-direction: column;
  }

  .input_field_login {
    padding: 12px;
    margin: 6px 0 4px;
    border: 1px solid #ccc;
    background: black;
    color: white;
    font-family: sans-serif;
    font-size: 12px;
    line-height: normal;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100% !important;
  }

  .touch {
    align-items: center;
  }

  .css-pm3uxj {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .form {
    margin-top: 30px;
  }
}
.contact_wrapper{
  max-width: 1440px;
  margin: auto;
  padding: 54px 131px 158px 124px;
}
.contact_heading{
  font-size: 40px;
  font-weight: 700;
  line-height: 30px;
  font-family: 'OPTIVenus', sans-serif;
  color: white;
}
.contact_sub_heading{
  max-width: 280px;
  display: flex;
  line-height: 24px;
  font-size: 16px;
  font-family: 'OPTIVenus', sans-serif;
  font-weight: 700;
  justify-content: flex-end;
}
.contact_us_form_wrap{
  display: flex;
  margin-top: 23px;
  width: 100%;
gap: 20px;
  justify-content: space-between;
}
.location_wrap{
  width: 376px;
  /*max-width: 100%;*/
  height: 206px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-family: 'OPTIVenus', sans-serif;
  padding: 28px 20px;
  border: 4px solid #182044;
  background-color: #03081F;
}
.loc_content{
  display: flex;
  gap: 20px;
  font-family: 'OPTIVenus', sans-serif;
  font-size: 12px;
  font-weight: 700;
  align-items: center;


}
.contact_left{

}

.form_info{
  width: 100%;
  height: 777px;
  padding: 16px 39px 37px 22px;
border-radius: 10px;
background-color: #03081F;
border: 4px solid #182044;
}
.con_us_text{
  font-size: 16px;
  font-weight: 700;
  font-family: 'OPTIVenus', sans-serif;
  line-height: 24px;
  color: white;
  text-decoration: underline;
}
.name_input_wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  gap: 30px;
}
.f_name_wrap{
  width: 331px;
  height: 53px;
  color: #ADABAB75;
  border-radius: 38px;
  border: 3px solid white;
}
.email_wrap{
  width: 100%;
  height: 53px;
  color: #ADABAB75;
  border-radius: 38px;
  border: 3px solid white;
}
.f_name_ele{
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 30px;
  outline: none;
  color: #ADABAB75;
  font-size: 14px;
  font-weight: 700;
}
.f_name_ele2{
  height: 100%;
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 10px;
  outline: none;
  color: #ADABAB75;
  font-size: 14px;
  font-weight: 700;
}
.enter_name{
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin-top: 14px;
}
.phon_wrap{
  width: 94px;
  height: 53px;
  border-radius: 38px;
  border: 3px solid white;
}
.phon_input_wrap{
  display: flex;
  gap: 14px;
  margin-top: 16px;
  align-items: center;
}
.text_area_werap{
  width: 100%;
  padding: 16px 25px;
  height: 229px;
  margin-top: 16px;
  border-radius: 38px;
  border: 3px solid white;
}
.text_area{
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  color: #ADABAB75;
  background: transparent;
}
.submit_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 44px;
  width: 112px;
  height: 45px;
  font-size: 16px;
  font-weight: 700;
  color: white;
  border-radius: 38px;
  border: 3px solid white;
  cursor: pointer;
}
@media screen and (max-width:1300px){
  .contact_wrapper{
    max-width: 1440px;
    margin: auto;
    padding: 54px 20px 158px 20px;
  }
}
@media screen and (max-width:1200px){
  .f_name_wrap{
    width: 100%;
    height: 53px;
    color: #ADABAB75;
    border-radius: 38px;
    border: 3px solid white;
  }
}
@media screen and (max-width:930px){
  .contact_us_form_wrap{
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    width: 100%;
  gap: 20px;
  align-items: center;
    justify-content: center;
  }
  .location_wrap{
    width: 100%;
    /*max-width: 100%;*/
    height: 206px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-family: 'OPTIVenus', sans-serif;
    padding: 28px 20px;
    border: 4px solid #182044;
    background-color: #03081F;
  }
  .contact_sub_heading{
    max-width: 280px;
    display: flex;
    line-height: 24px;
    font-size: 15px;
    font-family: 'OPTIVenus', sans-serif;
    font-weight: 700;
    justify-content: flex-end;
  }
  .contact_heading{
    font-size: 35px;
    font-weight: 700;
    line-height: 30px;
    font-family: 'OPTIVenus', sans-serif;
    color: white;
  }
}
@media screen and (max-width:475px){
  .name_input_wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    gap: 0px;
  }
  .name_scond_ip{
    margin-top: 20px;
  }
  .location_wrap{
    width: 100%;
    /*max-width: 100%;*/
    height: 206px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    font-family: 'OPTIVenus', sans-serif;
    padding: 28px 20px;
    border: 4px solid #182044;
    background-color: #03081F;
  }
  .get_toch_balls{
    margin-top: -30px;
  }
  .form_info{
    width: 100%;
    margin-top: -60px;
    height: auto;
    padding: 16px 20px 37px 20px;
  border-radius: 10px;
  background-color: #03081F;
  border: 4px solid #182044;
  }
  .loc_scond_ele{
    margin: 24px 0px;
  }
  .f_name_ele{
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding-left: 0px;
    outline: none;
    text-align: center;
    color: #ADABAB75;
    font-size: 14px;
    font-weight: 700;
  }
  .contact_wrapper{
    max-width: 1440px;
    margin: auto;
    padding: 54px 20px 50px 20px;
  }
  .submit_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
    width: 100%;
    height: 45px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    border-radius: 38px;
    border: 3px solid white;
    cursor: pointer;
  }
  .text_area_werap{
    width: 100%;
    padding: 16px 25px;
    height: 150px;
    margin-top: 16px;
    border-radius: 38px;
    border: 3px solid white;
  }
  .phon_input_wrap{
    display: flex;
    gap: 0px;
    margin-top: 16px;
    align-items: center;
  }
  .phone_second_ip{
    margin: 0px 14px;
  }
  .f_name_ele2{
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding-left: 0px;
    outline: none;
    text-align: center;
    color: #ADABAB75;
    font-size: 14px;
    font-weight: 700;
  }
  .text_area{
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #ADABAB75;
    background: transparent;
  }
}