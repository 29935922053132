@font-face {
  font-family: myFirstFont;
  src: url("../../Assests/font/OPTIVenus-BoldExtended.otf");
}


.whats_globex{
  max-width: 1440px;
  margin: auto;
  padding: 58px 143px 0px 42px;
}
.whats_globex2{
  max-width: 1440px;
  margin: auto;
  padding: 129px 56px 0px 51px;
}
.whats_globex_inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.whats_right_sec{
  max-width: 750px;
  position: relative;
  height: 250px;
  padding: 0px 42px;
}
.whats_right_sec2{
  max-width: 750px;
  position: relative;
  height: 150px;
  padding: 0px 42px;
}
.whats_globex_heading{
  max-width: 1440px;
  line-height: 60px;
  font-family: 'OPTIVenus', sans-serif;
  font-weight: 700;
  font-size: 40px;
  padding-left: 116px;
}
.whats_globex_heading2{
  max-width: 1440px;
  line-height: 40px;
  font-family: 'OPTIVenus', sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  font-size: 40px;
 
}
.globex_span{
border-bottom: 3px solid white;
}
.left_plus_icon{
  position: absolute;
  top: 0;
  left: 0;
}
.left_plus_icon2{
  position: absolute;
  bottom: 0;
  left: 0;
}
.right_plus_icon{
  position: absolute;
  bottom: 0;
  right: 0;
}
.right_plus_icon2{
  position: absolute;
  top: 0;
  right: 0;
}
.whats_globex_sub_heading{
  padding-bottom: 74px;
  padding-left: 116px;
  font-weight: 700;
  font-size: 14px;
  font-family: 'OPTIVenus', sans-serif;
  max-width: 500px;
  display: flex;
  justify-content: flex-end;
}
.whats_globex_sub_heading2{

  padding-left: 116px;
  font-weight: 700;
  font-size: 14px;
  font-family: 'OPTIVenus', sans-serif;
padding-left: 250px;
  display: flex;
  justify-content: center;
}
.whats_right_text{
  font-size: 16px;
  font-weight: 700;
  color: white;
  font-family: 'OPTIVenus', sans-serif;

  max-width: 700px;
  line-height: 24px;
  padding: 50px 20px 0px 20px;
}
.whats_right_text2{
  font-size: 16px;
  font-weight: 700;
  color: white;
  font-family: 'OPTIVenus', sans-serif;

  max-width: 700px;
  line-height: 24px;
  padding: 50px 10px 0px 20px;
}
@media screen and (max-width:1300px){
  .whats_globex{
    max-width: 1440px;
    margin: auto;
    padding: 58px 20px 0px 20px;
  }
  .whats_globex2{
    max-width: 1440px;
    margin: auto;
    padding: 80px 20px 0px 20px;
  }
}
@media screen and (max-width:900px){
  .whats_right_sec{
  width: 100%;
    position: relative;
    height: 250px;
    padding: 0px 22px;
  }
}
@media screen and (max-width:768px){
  .whats_globex_inner{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .whats_right_sec{
    max-width: 100%;
    position: relative;
    height: 260px;
    padding: 0px 25px;
  }
  .whats_right_text{
    font-size: 14px;
    font-weight: 700;
    color: white;
    font-family: 'OPTIVenus', sans-serif;
    max-width: 100%;
    line-height: 24px;
    padding: 20px 0px 0px 0px;
  }
  .whats_right_text2{
    font-size: 14px;
    font-weight: 700;
    color: white;
    font-family: 'OPTIVenus', sans-serif;
  
    max-width: 700px;
    line-height: 24px;
    padding: 50px 20px 0px 20px;
  }
  .whats_globex_heading{
    max-width: 1440px;
    line-height: 40px;
    font-family: 'OPTIVenus', sans-serif;
    font-weight: 700;
    font-size: 30px;
    display: flex;
    justify-content: center;
    padding-left: 0px;
  }
  .whats_globex_heading2{
    max-width: 1440px;
    line-height: 40px;
    font-family: 'OPTIVenus', sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: center;
    font-size: 30px;
   
  }
  .whats_globex_sub_heading{
    padding-bottom: 34px;
    padding-left: 0px;
    font-weight: 700;
    font-size: 14px;
    font-family: 'OPTIVenus', sans-serif;
    max-width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .whats_globex_sub_heading2{

    padding-left:0px;
    font-weight: 700;
    font-size: 14px;
    font-family: 'OPTIVenus', sans-serif;
  padding-left: 0px;
    display: flex;
    justify-content: center;
  }
  .whats_right_sec2{
    max-width: 100%;
    position: relative;
    height: 250px;
    margin-top: 30px;
    padding: 0px 0px;
  }
}