.banner_wrapper{
	height: 678px;
	max-width: 1440px;
	margin: auto;
	padding: 0px 30px 0px 0px;
	display: flex;

}
.vedio_dis{
	width: 100%;
}
.right_balls{
	/*height: 100%;*/
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.large_ball{
	height: 100%;
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.img-fluid1{
	width: 90%;
}
.learn_more_btn{
	width: 178px;
	height: 72px;
	background-color: #010720;
	border-radius: 38px;
	cursor: pointer;
	font-size: 13px;
	font-weight: 700;
	color: #596392;
	display: flex;
	font-family: 'OPTIVenus', sans-serif;
	justify-content: center;
	align-items: center;
	border: 1px solid #050E34;
}
.small_cir{
	z-index: 2;


}
.banner_btn_box{
	display: flex;
	justify-content: center;
}
.circle3{
	width: 123px;
	height: 88px;
	position: absolute;
	left: 80px;
	top: 70px;
}
.circle2{
	width: 262px;
	height: 187px;
	position: absolute;
	left: 140px;
	top: 20px;
}
.lern_more_link{
	text-decoration: none;
}
.circle1{
	position: absolute;
	left: 0;
width: 370px;
	bottom: 0;
}
.banner_sep{
	border-bottom: 1px solid #6A6666;
}
@media screen and (max-width:1230px){
	.circle1{
		position: absolute;
		left: 0;
	width: 300px;
		bottom: 0;
	}
	.circle2{
		width: 200px;
		height: 187px;
		position: absolute;
		left: 140px;
		top: 40px;
	}
	.circle3{
		width: 95px;
		height: 88px;
		position: absolute;
		left: 90px;
		top: 70px;
	}
	.small_cir{
		z-index: 2;
		position: relative;
	
	}

}
@media screen and (max-width:950px){
	.circle1{
		position: absolute;
		left: 0;
	width: 250px;
		bottom: 0;
	}
	.circle2{
		width: 160px;
		height: 187px;
		position: absolute;
		left: 120px;
		top: 60px;
	}
	.circle3{
		width: 75px;
		height: 88px;
		position: absolute;
		left: 90px;
		top: 70px;
	}
	.small_cir{
		z-index: 2;
		position: relative;

	}

}
@media screen and (max-width:768px){
	.banner_wrapper{
		height: 500px;
		max-width: 1440px;
		margin: auto;
		padding: 20px 10px 20px 0px;
		display: flex;
	
	}
	.vedio_dis{
		width: 180%;
	}
	.circle1{
		position: absolute;
		left: 0;
	width: 200px;
		bottom: 100px;
	}
	.img-fluid1{
		width: 100%;
	}
	.circle2{
		width: 160px;
		height: 187px;
		position: absolute;
		left: 90px;
		top: 0px;
	}
	.circle3{
		width: 75px;
		height: 88px;
		position: absolute;
		left: 60px;
		top: 10px;
	}
	.small_cir{
		z-index: 2;
		position: relative;
		
	}
}
@media screen and (max-width:650px){
	.circle1{
		position: absolute;
		left: 0;
	width: 170px;
		bottom: 100px;
	}
	.circle2{
		width: 100px;
		height: 187px;
		position: absolute;
		left: 90px;
		top: 0px;
	}
	.circle3{
		width: 55px;
		height: 88px;
		position: absolute;
		left: 60px;
		top: 10px;
	}
	.small_cir{
		z-index: 2;
		position: relative;
	
	}

}
@media screen and (max-width:450px){
	.circle1{
		position: absolute;
		left: 0;
	width: 120px;
		bottom: 90px;
	}
	.circle2{
		width: 70px;
		height: 187px;
		position: absolute;
		left: 60px;
		top: 3px;
	}
	.circle3{
		width: 35px;
		height: 88px;
		position: absolute;
		left: 45px;
		top: 10px;
	}
	.small_cir{
		z-index: 2;
		position: relative;
	
	}

}