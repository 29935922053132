.team_wrapper{
  max-width: 1440px;
  margin: auto;
  padding: 62px 142px 0px 142px;
}
.team_heading{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
}
.team_sub_heading{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
}
.team_sec{
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 24px;
  align-items: center;
}
.card_content_sec{
width: 100%;
height: 100%;
}
.team_card{
  height: 402px;
  width: 1157px;
  border-radius: 10px;
  background-color: #03081F;
  border: 4px solid #182044;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.team_card_profile{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.team_name{
  margin-top: 20px;
  font-weight: 700;
  font-size: 17px;
display: flex;
justify-content: center;
  text-align: center;
  max-width: 150px;
  line-height: 22px;
  font-family: 'OPTIVenus', sans-serif;
}
.team_card_heading{
  display: flex;
  position: relative;
  padding: 0px 0px 0px 30px;

  height: 30px;
  font-size: 16px;
  font-family: 'OPTIVenus', sans-serif;
  font-weight: 700;
  
  align-items: center;
}
.small_plus_left{
  position: absolute;
  left: 0;
  top: -7px;
}
.small_plus_right{
padding-top: 18px;

}
.team_card_text{
  max-width: 695px;
  font-weight: 700;
  font-size: 13px;
  line-height: 19px;
  padding-top: 70px;
  height: 280px;
  padding-left: 70px;
  color: white;
  font-family: 'OPTIVenus', sans-serif;
}
.team_card_social{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /*padding-top: 120px;*/
}
.card_social{
  display: flex;
  gap: 20px;
  align-items: center;
}
.social_icons{
  cursor: pointer;
}
@media screen and (max-width:1300px){
  .team_wrapper{
    max-width: 1440px;
    margin: auto;
    padding: 42px 20px 0px 20px;
  }
}
@media screen and (max-width:1200px){
  .team_sec{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 24px;
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
  }
  .team_card{
    height: auto;
    width: 100%;
    border-radius: 10px;
    background-color: #03081F;
    border: 4px solid #182044;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}
@media screen and (max-width:768px){
  .team_card{
    height: auto;
    width: 100%;
    border-radius: 10px;
    background-color: #03081F;
    border: 4px solid #182044;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .team_name{
    margin-top: 17px;
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    line-height: 22px;
    font-family: 'OPTIVenus', sans-serif;
  }
  .card_content_sec{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    .team_card_text{
      max-width: 695px;
      font-weight: 700;
      font-size: 13px;
      line-height: 19px;
      padding-top: 20px;
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      font-family: 'OPTIVenus', sans-serif;
    }
    .team_card_social{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 30px;
    }
    .team_card{
      height: auto;
      width: 100%;
      border-radius: 10px;
      background-color: #03081F;
      border: 4px solid #182044;
      padding: 30px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
    .team_card_heading{
      display: flex;
      position: relative;
      padding: 0px 0px 0px 0px;
    
      height: 30px;
      font-size: 16px;
      font-family: 'OPTIVenus', sans-serif;
      font-weight: 700;
      
      align-items: center;
    }
    .team_wrapper{
      max-width: 1440px;
      margin: auto;
      padding: 92px 20px 0px 20px;
    }
    .card_social{
      display: flex;
      gap: 0px;
      align-items: center;
    }
    .social_second_icon{
      margin: 0px 20px;
    }
}